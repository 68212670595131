<template>
  <p class="home-links">
    <a
      href="https://github.com/MYSTACK555"
      target="_blank"
      rel="noopener"
    >
      GitHub
    </a>
    <a
      href="https://www.growthbunker.dev/vuetimeline/?ref=madewithvuejs.com"
      target="_blank"
      rel="noopener"
    >
      Vue Timeline
    </a>
  </p>
</template>
<script>
export default {
  name: "FooterLinks",
};
</script>
<style>
.home-links a {
  margin-right: 1rem;
}
</style>